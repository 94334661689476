import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtilService } from '../../../services/app-util.service';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {

  sectionList: any[];
  articleDetail: any;
  isClick: boolean;
  categoriesId: number;

  constructor(private appUtilSerive: AppUtilService, private router: Router, private activatedRoute: ActivatedRoute) { 
    this.sectionList = [];
    this.articleDetail = null;
    this.isClick = false;
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.categoriesId) {
        this.categoriesId = +params.categoriesId;
      }
    })
  }

  ngOnInit() {
    this.appUtilSerive.getSectionsByCategoriesId(this.categoriesId).subscribe(response => {
      // this.sectionList = response.sections;
      response.sections.forEach((ele, index) => {
        if (ele.name.toLowerCase() === 'get in touch!') {
          this.appUtilSerive.getArticleListById(ele.id).subscribe(response => {
            this.articleDetail = response.articles[0];
          }, error => { });
        } else {
          this.sectionList.push(ele);
        }
      });
    }, error => { });
  }

  watchArticles(id: number) {
    this.router.navigateByUrl('/help/faq/'+ this.categoriesId + '/' + id);
  }

  checkClick() {
    if (!this.isClick) {
      this.isClick = true;
    }
  }

}
