import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { PlatformIdentifierService } from '../../services/platform-identifier.service';
import { AppConstants } from '../../typings/common-constants';
declare var $: any;
import { TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AppUtilService } from '../../services/app-util.service';
import { MyDishTvSpaceService } from '../../../pages/default/my-dish-tv-space/services/mydishtvspace.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent implements OnInit {
  hideCookiesBanner: boolean = false;
  hidePopupBanner: boolean = false;
  privacyPolicy: string = AppConstants.PRIVACY_POLICY;
  isDesktopView: boolean = false;
  OTTimage:any =  `${environment.AWS_S3_URL}Watcho_omni/Banner/Interstitial_Banner_Web.png`
  @ViewChild('content')
  private content: TemplateRef<any>;
  modalReference: any
  isBrowser;
  isUserLogin: boolean;
  userCategory: any;
  userDetails: any;

  constructor(private platformIdentifierService: PlatformIdentifierService,
    private modalService: NgbModal,
    private router: Router,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    public appUtilService: AppUtilService, 
    private mydishtvspaceservice: MyDishTvSpaceService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.userCategory = this.mydishtvspaceservice.getUserCategory();
    if (this.isBrowser) {
      if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
        this.isUserLogin = true;
        this.userDetails = JSON.parse(localStorage.getItem(AppConstants.USER_DETAILS_SMS))
      }
    }
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   // if(this.isUserLogin){
    //     localStorage.setItem('popup-allowd', 'true');
    //   // }
    // })
  }

  ngOnInit() {
    if (this.platformIdentifierService.isBrowser()) {
      if (matchMedia('(min-width: 992px)').matches) {
        this.isDesktopView = true;
      } else {
        this.isDesktopView = false;
      }
      $(window).bind("orientationchange", () => {
        setTimeout(() => {
          if (matchMedia('(min-width: 992px)').matches) {
            this.isDesktopView = true;
          } else {
            this.isDesktopView = false;
          }
        }, 400)
      });
    }
    // if(this.isDesktopView){
    //   let popupValue = localStorage.getItem('popup-allowd')
    //   if (!popupValue && location.pathname.toString().indexOf("login") === -1) {
    //     this.modalReference = this.modalService.open(this.content, {
    //       ariaLabelledBy: 'modal-basic-title',
    //       windowClass: 'OTTModel'
    //     });
    //   }
    // }
    this.isCookiesAllowed();
  }

  isCookiesAllowed() {
    if (this.platformIdentifierService.isBrowser()) {
      if (localStorage.getItem('cookies-allowd')) {
        this.hideCookiesBanner = true;
      }
    }
  }

  cookiesAllowed() {
    localStorage.setItem('cookies-allowd', 'true');
    this.isCookiesAllowed();
  }

  close() {
    // if(this.isUserLogin){
      localStorage.setItem('popup-allowd', 'true');
    // }
    this.modalReference.close()
  }

  closePopup() {
    // if(this.isUserLogin){
      localStorage.setItem('popup-allowd', 'true');
    // }
    this.modalReference.close()
    this.router.navigate(['/OTTPlans'])
  }

  saveGaGtmEvents(eventName) {
    let userType = ''
    let ID = ''
    if(this.userDetails && this.userDetails.OTTSubscriberID){
      ID = this.userDetails.OTTSubscriberID
    }
    else{
      ID = null
    }
    if (this.userCategory == '1') {
      userType = 'dish user'
    }
    else if (this.userCategory == '2') {
      userType = 'd2h user'
    }
    else if (this.userCategory == '3') {
      userType = 'Watcho user'
    }
    else if (this.userCategory == '4') {
      userType = 'siti user'
    }
    else {
      userType = 'Anonymous user'
    }
    let datalayerJson = {
      'user_type': `${userType}`,
      'kaltura_ID': ID
    };
    this.appUtilService.getGTMTag(datalayerJson, `${eventName}`);
  }
}
