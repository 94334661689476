import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUtilService } from '../../services/app-util.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  categoriesList: any[] = [];

  constructor(private router: Router, private appUtilService: AppUtilService) { }

  ngOnInit() {
    this.appUtilService.getCategories().subscribe(response => {
      if (response.categories && response.categories.length > 0) {
        this.categoriesList = response.categories;
      }
    }, error => { });
  }

  watchArticles(id: number) {
    this.router.navigateByUrl('/help/faq/' + id);
  }

}
