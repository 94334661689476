import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MyDishTvSpaceService } from '../../pages/default/my-dish-tv-space/services/mydishtvspace.service';
import { OmniService } from '../../pages/default/Omni/omni.service';
import { UserFormService } from '../../pages/default/user-management/services/user-form.service';
import { AppUtilService } from '../services/app-util.service';
import { LoginMessageService } from '../services/auth-message.service';
import { KalturaAppService } from '../services/kaltura-app.service';
import { PlatformIdentifierService } from '../services/platform-identifier.service';
import { SmsFormService } from '../services/sms-form.service';
import { SnackbarUtilService } from '../services/snackbar-util.service';
import { AppConstants, SMSConstants } from '../typings/common-constants';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  verifyNumberForm: FormGroup
  verifyOTPForm: FormGroup
  userMobileNumber: any;
  loading: boolean;
  validationToken: any;
  isBrowser: boolean;
  devicesLimit: any;
  householdId: any;
  currentURL: string;
  OTTSubscriberID: any;
  modalReference: any;
  eligibilityText
  @ViewChild('offerEligibility')
  private offerEligibility: TemplateRef<any>;
  successPage: any = `${environment.AWS_S3_URL}Watcho_omni/bogoCongratulations.png`
  userDetailsSSO: any;
  authToken: any;
  consentGiven: boolean = false;
  termsOfUse: string = AppConstants.TERMS_OF_USE
  privacyPolicy: string = AppConstants.PRIVACY_POLICY
  className: string;
  resendOTPButton: boolean = false;
  constructor(
    private mydishtvspaceservice: MyDishTvSpaceService,
    private snackbarUtilService: SnackbarUtilService,
    private modalService: NgbModal,
    public appUtilService: AppUtilService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId,
    private userFormService: UserFormService,
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private smsService: SmsFormService,
    private loginMessageService: LoginMessageService,
    private kalturaAppService: KalturaAppService,
    public activeModal: NgbActiveModal,
    private OMNIService: OmniService,
    private platformIdentifierService: PlatformIdentifierService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.currentURL = router.url
  }

  ngOnInit(): void {
    if (this.platformIdentifierService.isBrowser()) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    }
    this.verifyNumberForm = this.fb.group({
      mobileNumber: ['', Validators.required],
    })
    this.verifyOTPForm = this.fb.group({
      verifyOTP: ['', Validators.required],
    })
  }

  fieldsChange(values: any): void {
    let checkEvent = values.currentTarget.checked
    if (checkEvent) {
      this.consentGiven = true
    }
    else {
      this.consentGiven = false
    }
  }

  checkMobileNumber(evt) {
    if (evt.srcElement.value.length == 0 && evt.which == 48) {
      evt.preventDefault();
      return false;
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  verifyNumber() {
    this.userMobileNumber = this.verifyNumberForm.value.mobileNumber
    if (!this.userMobileNumber || this.userMobileNumber.length < 10) {
      let error = "Please enter a valid 10-digit Mobile Number"
      this.snackbarUtilService.showSnackbar(error);
      return
    }
    let letter = this.userMobileNumber.charAt(0);
    if (letter == '0') {
      let error = "Please enter a valid 10-digit Mobile Number"
      this.snackbarUtilService.showSnackbar(error);
      return
    }
    this.loading = true
    this.saveGAGTMLoginInitialted()
    this.smsService.newUserLogin(this.userMobileNumber.trim()).subscribe((response: any) => {
      this.loading = false
      if (response.ResultCode == 0) { // 1000 on success
        if (this.isBrowser) {
          localStorage.setItem('loginToken', response.Result);
        }
        this.generateToken()
      } else {
        this.snackbarUtilService.showSnackbar(response.ResultDesc)
      }
    }, (error) => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  generateToken() {
    let value = {
      "mobileNo": this.userMobileNumber,
      "processType": "Login",
      "source": "Web"
    }
    this.smsService.GenerateOTP(value).subscribe((response: any) => {
      if (response.ResultCode == 1001) {
        this.resendOTPButton = true
        this.snackbarUtilService.showSnackbar('We have sent you an OTP on ' + this.userMobileNumber.substr(0, 4) + 'xxxxxx. it is valid for 5 miniutes.')
      }
      else {
        this.snackbarUtilService.showSnackbar(response.ResultDesc)
      }
    })
  }

  inputHandle(event) {
    var number = event.target.value;
    if (number.length >= 4) {
      // this.verifyOTP();
    }
  }

  resendOtp() {
    if (!this.userMobileNumber || this.userMobileNumber.length < 10) {
      let error = "Please enter a valid 10-digit Mobile Number."
      this.snackbarUtilService.showSnackbar(error);
      return
    }
    this.loading = true
    this.smsService.ResendOtp(this.userMobileNumber, this.userMobileNumber, this.validationToken).subscribe((res: any) => {
      this.loading = false
      if (res.ResultCode > 0) {
        this.snackbarUtilService.showSnackbar('We have sent you an OTP on ' + this.userMobileNumber.substr(0, 4) + 'xxxxxx. it is valid for 5 miniutes.')
      } else {
        this.snackbarUtilService.showError(res.ResultDesc)
      }
    }, (error) => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }


  verifyOTP() {
    if (!this.userMobileNumber || this.userMobileNumber.length < 10) {
      let error = "Please enter a valid 10-digit Mobile Number."
      this.snackbarUtilService.showSnackbar(error);
      return
    }
    if (!this.consentGiven) {
      let error = "Please confirm the age."
      this.snackbarUtilService.showSnackbar(error);
      return
    }
    let OTP = this.verifyOTPForm.value.verifyOTP
    let value = {
      "mobileNo": this.userMobileNumber,
      "processType": "Login",
      "source": "Web",
      "OTP": OTP
    }
    this.loading = true
    this.smsService.ValidateOTP(value).subscribe((response: any) => {
      if (response.ResultCode == 1004) {
        this.authToken = response.Result
        this.getUserDetailsSSO(this.userMobileNumber, this.authToken)
      }
      else {
        this.loading = false
        this.snackbarUtilService.showSnackbar(response.ResultDesc)
      }
    })
  }

  getUserDetailsSSO(UserId, token) {
    let userId = UserId
    let UserType = 'mobile'
    this.userFormService.getUserDetailsSSO(userId, UserType, token).subscribe((res: any) => {
      if (res.ResultCode == 100) {
        this.userDetailsSSO = res.Result
        this.validationToken = res.Result.DishToken
        this.SSOLogin()
      }
      else {
        this.loading = false
        this.snackbarUtilService.showSnackbar(res.ResultDesc)
      }
    })
  }
  SSOLogin() {
    localStorage.setItem(AppConstants.USER_CATEGORY, this.userDetailsSSO.UserCategory);
    localStorage.setItem(AppConstants.USER_DETAILS_SMS, JSON.stringify(this.userDetailsSSO));
    localStorage.removeItem(AppConstants.IS_DISH_USER);
    if (Number(this.userDetailsSSO.UserCategory === 1) || Number(this.userDetailsSSO.UserCategory === 2)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'true');
    } else if (Number(this.userDetailsSSO.UserCategory === 3)) {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    } else {
      localStorage.setItem(AppConstants.IS_DISH_USER, 'false');
    }
    this.kalturaAppService.userLogin(this.userDetailsSSO.OTTSubscriberID, this.validationToken).then(response => {
      if (response.error) {
        this.loading = false;
        this.snackbarUtilService.showSnackbar(response.error.message)
      }
      else {
        if (response.loginSession.ks) {
          this.loading = false;
          this.loginMessageService.sendLoginMessage(true)
          if (this.isBrowser) {
            localStorage.removeItem(AppConstants.AUTH_HEADER_KEY)
            localStorage.setItem(AppConstants.AUTH_HEADER_KEY, this.authToken)
            localStorage.setItem(AppConstants.KS_KEY, response.loginSession.ks)
            localStorage.removeItem(AppConstants.USER_DETAILS)
            localStorage.setItem(AppConstants.USER_DETAILS, JSON.stringify(response.user))
          }
          this.kalturaAppService.setKs()
          this.snackbarUtilService.showSnackbar('Logged in successfully')
          this.houseHold();
          this.extendToken();
          this.activeModal.close(response.user)
          this.saveGAGTMLoginCompleted();
          let category = this.userDetailsSSO.UserCategory
          let platform = category == '1' ? 'mydishtvspace' : 'myd2hspace';
          if (this.currentURL == '/bookDTH') {
            this.router.navigate([`/user/${platform}`]);
          }
          this.OTTSubscriberID = this.mydishtvspaceservice.getOTTSubscriberID();
          this.userOfferEligibility()
        } else {
          this.loading = false;
          this.snackbarUtilService.showError()
        }
      }
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  gtmTagOnEnteredOTP() {
    let dataLayerJson = {
      'button_id': 'continue_with_entered_otp',
      'button_name': 'Continue',
      'redirection_url': null,
      'button_image': null,
      'successful': 'Successful',
      'button_location': 'Sign_In_Page'
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'continue_otp');
  }

  loginCompleteMoEngageEventWithOTPForMobileNo() {
    let loginComplete = {
      id_type: 'mobile_number_watcho',
      authentication_type: 'OTP',
      status: 'login_successful'
    }
    this.appUtilService.moEngageEventTracking('LOGIN_COMPLETE', loginComplete);
  }

  extendToken() {
    this.loading = true
    this.kalturaAppService.addToken().then(res => {
      this.loading = false
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  houseHold() {
    this.loading = true
    this.kalturaAppService.getHousehold().then(res => {
      this.loading = false
      this.devicesLimit = res.devicesLimit
      this.householdId = res.id
      this.getHouseholdDeviceList()
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }


  getHouseholdDeviceList() {
    this.loading = true
    this.kalturaAppService.getHouseholdList().then(res => {
      this.loading = false
      if (this.checkDeviceAlreadyAdded(res)) {
        this.reloadActivatedRoute();
      } else {
        this.addDeviceToHousehold();
      }
      // }

    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError()
    })
  }

  checkDeviceAlreadyAdded(res): boolean {
    let isDeviceAdded = false
    if (this.isBrowser) {
      let udid = this.appUtilService.getDeviceId();
      if (res.objects) {
        res.objects.forEach((element, index) => {
          if (element.udid === udid) {
            isDeviceAdded = true
          }
        })
      }
      return isDeviceAdded
    }
  }

  addDeviceToHousehold() {
    this.loading = true
    this.kalturaAppService.addHouseholdDevice(this.appUtilService.getBrowserDetails()).then(response => {
      this.reloadActivatedRoute();
    }, reject => {
      this.loading = false
      this.snackbarUtilService.showError(reject.message);
    })
  }

  reloadActivatedRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/search', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(currentUrl));
  }

  loginCompleteMoEngageEvent(userEmailContactInput: any) {
    let loginComplete: any;
    loginComplete = {
      id_type: 'mobile_number_watcho',
      authentication_type: 'password',
      status: 'login_successful'
    }
    this.appUtilService.moEngageEventTracking('LOGIN_COMPLETE', loginComplete);
  }

  gtmTagOnSignIn() {
    let getTimeStamp = this.appUtilService.getGTMUserID();
    let dataLayerJson = {
      'button_id': 'sign_in_with_watcho_user',
      'button_name': 'Sign In',
      'redirection_url': null,
      'button_image': null,
      'successful': 'Successful',
      'user_id': getTimeStamp,
      'button_location': 'Sign_In_Page'
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'sign_in');
  }

  userOfferEligibility() {
    this.loading = true;
    this.OMNIService.userOfferEligibility(this.OTTSubscriberID).subscribe((response: any) => {
      if (response.ResultDesc == 'Success') {
        this.modalReference = this.modalService.open(this.offerEligibility, {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: 'OTTModel'
        });
        this.eligibilityText = response.Result[0].OfferDiscription
        this.loading = false;
      }
    })
  }

  onBogoContinue() {
    this.modalReference.close()
    this.router.navigate(['/OTTPlans']);
  }

  gtmTagOnTermsAndConditions() {
    let dataLayerJson = {
      'button_id': 'terms_of_use',
      'button_name': 'Terms of Use',
      'redirection_url': null,
      'button_image': null,
      'successful': 'Successful',
      'button_location': 'Sign_In_Page'
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'terms_of_use');
  }

  gtmTagOnPrivacyPolicy() {
    let dataLayerJson = {
      'button_id': 'privacy_policy',
      'button_name': 'Privacy Policy',
      'redirection_url': null,
      'button_image': null,
      'successful': 'Successful',
      'button_location': 'Sign_In_Page'
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'privacy_policy');
  }

  closePopup() {
    this.modalService.dismissAll()
  }

  saveGAGTMLoginInitialted() {
    let dataLayerJson = {
      userType: 'Anonymous user',
      mobile_number: this.userMobileNumber
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'user_registration_initiated');
  }

  saveGAGTMLoginCompleted() {
    let dataLayerJson = {
      userType: 'Anonymous user',
      mobile_number: this.userMobileNumber,
      kaltura_ID: this.userDetailsSSO.OTTSubscriberID
    };
    this.appUtilService.getGTMTag(dataLayerJson, 'user_registration_completed');
  }
}
